
import { useLocation } from "react-router-dom";
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/images/logo.webp';
// import { socialMedia } from './helper.js';

export default function NavBar() {

  // define button style by darkMode
  const { darkMode } = useThemeContext();
  const { variant, bg } = switchTheme(darkMode);
  const location = useLocation(); // Get current location
    
  // const [fb, ig] = socialMedia;

  return <>
    <div className='pb-3 mt-5'>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg={bg} variant={variant}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width={"40%"}
              className="d-inline-block align-top"
              alt="Company Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav 
                variant="underline" 
                activeKey={location.pathname} 
                className="me-auto"
              >
              <Nav.Item>
                <Nav.Link href="/">Welcome</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/business-opportunities"} href="/business-opportunities">Business Opportunities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/about"} href="/about">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/contact"} href="/contact">Contact Us</Nav.Link>
              </Nav.Item>
              <Nav.Item></Nav.Item>
            </Nav>
            {/* <Nav className="font-weight-light mx-2"> */}
              {/* <Nav.Link href={fb} target="_blank" rel='noreferrer'>
                <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
              </Nav.Link>
              <Nav.Link href={ig} target="_blank" rel='noreferrer'>
                <Icon icon="ph:instagram-logo" style={ iconStyle }/>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link> */}
            {/* </Nav> */}
            <Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>;
}
