
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor } = switchTheme(darkMode);

	// const style = {color: '#0e65d7'};
	// const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto", width:'15%'};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center ${mainBannerTextColor}`}>
									Hi I'm LaToya!
								</h4>
								<p>
									Hi I'm LaToya and I'm happily married to Kevin, my husband of 14yrs. We have 7 adult children, 15 grandkids, no pets.  I love dogs but I've not been able to bring myself to own another since my last dog passed away. My husband and I love to travel and go to concerts.  We love 90's R&B and we travel within the US.
								</p>
								<p>
									I had a corporate job for 14 yrs and I got burnt-out with the hours and the corporate environment. But what made me leave was the constant feeling that I was not moving forward on my financial life.  I then started as a PGA (Professional  Golf Association) mixologist in 2016 and I got to travel with better pay.  The business travel got old very quickly specially because I'm family oriented and this was too much time away from them.  I decided that our family's way out of this circus was for me to become and entrepreneur and run my own business. In 2017 I started doing taxes while I was traveling with the PGA.  I grew my clientele list very fast and in just a few years I quit the PGA job right before COVID hit in 2019.
								</p>
								<p>
									I truly enjoy working with you and helping your family and business with your taxes. My goal is to handle your diverse tax needs while giving each of you the personal attention you deserve. My goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container className="text-center">
							{/* <h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center ${mainBannerTextColor}`}>
								My Commitment to You!
							</h5>
							<p>
								My goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-warning`}
						style={{maxWidth: '300px', margin: 'auto'}}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
